import { AUTH_USER_STORE_NAME } from "~~/composables/states";
import { LoginResponse } from "~~/composables/useLogin";

/**
 * Require Users Authentication
 */
export default defineNuxtRouteMiddleware((to, from) => {
  let user: LoginResponse | null = null;

  const isClient = process.client;
  const isServer = process.server;

  if (isServer) {
    user = useCookieParser().value[AUTH_USER_STORE_NAME] ?? null;
  } else if (isClient) {
    user = useAuthUser().value;
  }

  // Not Logged In at all redirect to login
  if (user && user?.access_token) {
    useToast().info("You are already logged in.");

    if (isServer) {
      return navigateTo("/forms");
    } else if (isClient) {
      if (from.path === to.path) {
        // possible first refreshed/load
        return navigateTo("/forms");
      } else {
        return abortNavigation();
      }
    }
  }
});
